button {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: center;
  font-size: 1.1em;
  font-weight: 600;
  padding: 0.75em 2em;
  border: solid 1px var(--socraft-dark);
  color: var(--socraft-dark);
  background-color: transparent;
  cursor: pointer;
  border-radius: 20px;
  transition: all 100ms linear;
}

button:not(.navigation-tab):hover {
  opacity: 0.8;
}

button.primary {
  border-color: var(--socraft-yellow);
  background-color: var(--socraft-yellow);
}

button.secondary {
  background-color: var(--socraft-dark);
  color: var(--white);
}

button.navigation-tab {
  border: none;
  justify-content: flex-start;
  gap: 1em;
  font-size: 0.95em;
  transition: all 100ms linear;
  width: 100%;
  font-weight: 600;
  letter-spacing: 0.1px;
  border-radius: 10px;
  padding: 0.75em 1.5em;
  cursor: pointer;
  animation: appear 300ms ease-in;
  border: solid 3px transparent;
}

@keyframes appear {
  from {
    opacity: 0;
  }
}

.tabs-label {
  display: flex;
  align-items: center;
  padding: 0.75em 1.5em;
  cursor: pointer;
  gap: 1em;
  font-weight: 600;
  letter-spacing: 0.1px;
  font-size: 0.95em;
  margin: 1.1em 0 0 0;
  border-radius: 10px;
  transition: all 100ms linear;
}

.tabs-label .icon {
  transition: all 100ms ease-in;
}

.tabs-label.open .icon {
  transform: rotate(180deg);
}

.tabs-label::selection {
  background-color: transparent;
}

.tabs-content .navigation-tab {
  width: 77%;
  float: right;
  margin: 0.5em 0 0 0;
}

.tabs-content .navigation-tab:last-child {
  margin: 0.5em 0 1em 0;
}

button.navigation-tab:not(.current):hover,
.tabs-label:hover {
  background-color: #28282808;
}

button.navigation-tab.current {
  background-color: var(--socraft-dark);
  color: var(--white);
}

button.navigation-tab:not(:first-child) {
  margin: 1.1em 0 0 0;
}
