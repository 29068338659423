.skeleton {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 10px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.sidebar nav .skeleton:not(:first-child) {
  margin: 1em 0 0 0;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
