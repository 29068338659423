@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import "./cvUploader.css";
@import "./toggleable.css";
@import "./searchBar.css";
@import "./checkbox.css";
@import "./skeleton.css";
@import "./sidebar.css";
@import "./buttons.css";
@import "./crafter.css";
@import "./loader.css";
@import "./toast.css";
@import "./input.css";
@import "./popup.css";
@import "./card.css";
@import "./flex.css";

:root {
  --socraft-dark: #282828;
  --socraft-yellow: #fbbb10;

  --default-page-background: #f7f6f2;

  --white: #fff;
  --success: #62bd19;
  --danger: #ff0e0ed6;

  --box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 3px 0px;
  --border-color: #28282827;

  --uib-size: 60px;
  --uib-speed: 0.5s;
}

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif !important;
}

body {
  background: url("../images/background.svg") bottom fixed no-repeat,
    var(--default-page-background);
}

.yellow {
  color: var(--socraft-yellow);
}

a {
  color: var(--socraft-yellow);
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

::selection {
  background-color: #fbbb1050;
}

.container {
  width: 100%;
  height: 90vh;
}

.logo.small {
  height: 25px;
}

.logo.medium {
  height: 50px;
}

.logo.large {
  height: 150px;
}

@media screen and (max-width: 900px) {
  .logo.large {
    height: 75px;
  }
}

.authentication {
  margin: 5em 7em;
}

.authentication .authentication-content {
  margin: 3em 0 0 0;
  display: flex;
  align-items: center;
  gap: 3em;
}

@media screen and (max-width: 595px) {
  .authentication {
    margin: 2em;
  }
}

.authentication .authentication-content .auth-title {
  font-size: 2em;
}

@media screen and (max-width: 1120px) {
  .authentication .authentication-content {
    flex-direction: column;
    align-items: flex-start;
    margin: 2em 0 0 0;
  }

  .authentication .authentication-content .auth-title {
    margin-block-end: 0;
  } 
}

.join-community {
  display: flex;
  align-items: center;
  gap: 3em;
  margin: 3em 0 0 0;
}

.join-community .text {
  width: max-content;
  display: flex;
  align-items: center;
  gap: 1em;
}

@media screen and (max-width: 805px) {
  .join-community {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.page {
  margin: 4.5em 10em 0 22em;
  padding: 0 0 15em 0;
}

@media screen and (max-width: 1350px) {
  .page {
    margin: 2.5em 8em 0 18em;
  }
}

@media screen and (max-width: 1060px) {
  .page {
    margin: 6.5em 1em 2.5em 2em;
  }
}

.page header {
  display: flex;
  align-items: center;
  gap: 2em;
}

@media screen and (max-width: 570px) {
  .page header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
  }
}

.page header .icon {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  padding: 2em;
  border-radius: 35px;
  color: var(--socraft-yellow);
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.page header .icon:has(.avatar) {
  padding: 0.6em;
}

.page header .icon .avatar {
  height: 110px;
  border-radius: calc(35px - 0.6em);
  position: relative;
}

.page header .icon:has(.avatar)::before {
  content: "Modifier";
  background-color: #ffffff95;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 999;
  transition: all 100ms linear;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--socraft-dark);
  font-size: 1.2em;
  font-weight: 600;
  border-radius: inherit;
  cursor: pointer;
}

.page header .icon:hover::before {
  opacity: 1;
}

.page header .text .page-title {
  font-size: 2.3em;
  height: max-content;
  margin-top: 0;
  margin-block-end: 0.2em;
}

.page header .text .date,
.crafters-count {
  font-size: 1.1em;
  color: var(--socraft-yellow);
  font-weight: 800;
}

.page header .text .date {
  text-transform: capitalize;
}

.grid {
  margin: 4em 0 0 0;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  width: 100%;
  gap: 2em;
}

.grid:has(.crafter-card) {
  grid-template-columns: repeat(3, 3fr);
}

.block {
  margin: 4em 0 0 0;
  width: 100%;
}

.float-right {
  float: right;
}

iframe.headquarter-position {
  height: 350px;
  width: 100%;
  border: none;
  border-radius: 20px;
}

span:has(.value) {
  display: flex;
  align-items: center;
} 

span .value {
  background-color: var(--socraft-yellow);
  color: var(--socraft-dark);
  padding: 0.35em 1.2em;
  border-radius: 10px;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin: 0 0 0 1em;
  white-space: nowrap;
}

span:has(.value).wifi-prop {
  align-items: center;
}

span:has(.value).wifi-prop:not(:first-child) {
  margin: 1em 0 0 0;
}

@media screen and (max-width: 440px) {
  span:has(.value).wifi-prop {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 1225px) {
  span:has(.value) {
    align-items: flex-start;
    gap: 0.5em;
    margin: 1em 2em;
  }
}

@media screen and (max-width: 895px) {
  span:has(.value) {
    margin: 1em 0;
  }
}

@media screen and (max-width: 1615px) {
  .printer {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

@media screen and (max-width: 1230px) {
  .printer div span:has(.value) {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 560px) {
  .printer div span .value {
    white-space: wrap;
  }
}

@media screen and (max-width: 490px) {
  .printer img {
    height: 150px !important;
  }
}

.doors {
  margin: 1em 0;
}

.doors .buttons,
.doors .statuses {
  display: flex;
  align-items: center;
  gap: 1em;
}

@media screen and (max-width: 785px) {
  .doors .buttons {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 750px) {
  .doors .statuses {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.doors .statuses {
  margin: 2em 0 0 0;
}

.doors .statuses .status {
  width: max-content;
  font-weight: 700;
}

@media screen and (max-width: 415px) {
  .doors .statuses .status {
    max-width: 100%;
  }
}

.doors .statuses .status.ok {
  border: solid 1px var(--success);
  color: var(--success);
  background-color: #62bd1908;
}

.doors .statuses .status.loading {
  color: var(--socraft-dark);
  border: solid 1px var(--socraft-dark);
  background-color: #28282808;
  opacity: 0.7;
}

.doors .statuses .status.other {
  border: solid 1px var(--danger);
  color: var(--danger);
  background-color: #ff0e0e08;
}

form .actions {
  display: flex;
  align-items: center;
  gap: 2em;
}

form .actions button {
  width: 100%;
}

form .actions.floating {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  padding: 0.5em 1em;
  border-radius: 20px;
  margin: 2em 0 0 0;
  position: fixed;
  left: 22.5em;
  right: 10.5em;
  bottom: 3.5em;
  z-index: 99;
}

@media screen and (max-width: 1320px) {
  form .actions.floating {
    left: 16em;
    right: 1em;
    bottom: 1em;
  }
}

@media screen and (max-width: 1065px) {
  form .actions.floating {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5em;
  }
}

@media screen and (max-width: 1060px) {
  form .actions.floating {
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 0;
  }
}

iframe[title="Calendar"] {
  border: none;
  box-shadow: 0 0 2px 0 rgba(145,158,171,.2), 0 12px 24px -4px rgba(145,158,171,.2);
  box-shadow: var(--socraft-default-box-shadow);
  height: 35em;
  width: 100%;
}
