.flex {
  display: flex;
}

.flex.align-center {
  align-items: center;
}

.flex.space-between {
  justify-content: space-between;
}

.flex.column {
  flex-direction: column;
}

.flex.justify-end {
  justify-content: flex-end;
}

.flexbox {
  margin: 3em 0 0 0;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: auto;
  align-content: flex-start;
}

.flexbox .card {
  min-width: 600px;
  max-width: 700px;
}

@media screen and (max-width: 1350px) {
  .flexbox .card {
    min-width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 895px) {
  .place-header {
    display: block;
    margin: 0 0 2em 0;
  }
}

@media screen and (max-width: 735px) {
  header.flex.crafter-popup {
    flex-direction: column;
    align-items: flex-start;
  }

  header.flex.crafter-popup .side .crafter-fullname {
    margin-block-start: 0;
  }
}

@media screen and (max-width: 510px) {
  .flexbox .crafter-card .grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flexbox .crafter-card .grid .crafter-avatar {
    width: 120px;
    height: 120px;
  }

  .flexbox .crafter-card .grid .crafter-fullname,
  .flexbox .crafter-card .grid .crafter-actions,
  .flexbox .crafter-card .grid .crafter-infos {
    width: max-content;
  }

  .flexbox .crafter-card .grid .crafter-infos {
    align-items: center;
  }

  .flexbox .crafter-card .grid .crafter-infos .crafter-info::before {
    display: none;
  }

  .flexbox .crafter-card {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }
}

@media screen and (max-width: 1225px) {
  .place-header span {
    flex-direction: column;
  }

  .place-header span .value {
    width: max-content;
    margin: 0 0 0 -0.1em;
  }
}
