.toggleable {
  width: 100%;
}

.toggleable:not(:first-child) .label {
  margin: 1em 0 0 0;
}

.toggleable .label {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  padding: 0.75em 1.5em;
  font-size: 1.2em;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 20px;
}

.toggleable .label::selection {
  background-color: transparent;
}

.toggleable .content {
  display: none;
  transition: all 250ms ease-in-out;
  background-color: var(--white);
  width: 100%;
  padding: 1.5em 3em 2em 3em;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  margin: 1em 0 0 0;
  position: relative;
  animation: appear 100ms linear;
}

@keyframes appear {
  from {
    opacity: 0;
  }
}

.toggleable.opened .content {
  display: block;
  transition: all 250ms ease-in-out;
}

.toggleable .label .icon {
  transition: all 250ms ease-in-out;
}

.toggleable.opened .label .icon {
  transform: rotate(135deg);
}

@media screen and (max-width: 715px) {
  .toggleable .content {
    padding: 1em 2em 2em 2em;
  }
}
