.card {
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  padding: 1em 2.5em;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.card-loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: #fffffff7;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  animation: appear 10ms linear;
  padding: 0 3em;
}

.card-loading div {
  text-align: center;
}

.card-loading div .jelly {
  margin: 0 auto 2em auto;
}

.card-loading div span {
  font-size: 1.7em;
  font-weight: 600;
}

@keyframes appear {
  from {
    opacity: 0;
  }
}

.card.localsPreview {
  background-image: url(../images/custom/localspreview.jpg);
  background-position: 0;
  background-size: cover;
  min-height: 20em;
}

.card header {
  margin: 0 0 2em 0;
}

.card footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0.5em;
  background-color: var(--white);
}
