.cv-uploader {
  background-color: var(--white);
  padding: 1em 2em;
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  margin: 1.5em 0 0 0;
  border: dashed 2px var(--socraft-yellow);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.cv-uploader .label {
  font-size: 1.1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: center;
}

.cv-uploader *::selection {
  background-color: transparent !important;
}

.cv-uploader input {
  display: none;
}

.status {
  margin: 10px 0 0 10px;
}

.status span {
  display: flex;
  align-items: center;
  gap: 10px;
}
