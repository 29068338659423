.popup {
  width: 40vw !important;
  padding: 1em 2em 2em 2em !important;
  border-radius: 1em 3em !important;
}

@media screen and (max-width: 1325px) {
  .popup {
    width: 60vw !important;
  }
}

@media screen and (max-width: 900px) {
  .popup {
    width: 90vw !important;
  }
}

div:where(.swal2-icon).swal2-info,
div:where(.swal2-icon).swal2-question {
  border-color: #fbbb10 !important;
  border-color: var(--socraft-yellow) !important;
  color: #fbbb10 !important;
  color: var(--socraft-yellow) !important;
}

div:where(.swal2-container) div:where(.swal2-actions) {
  justify-content: flex-end !important;
  margin: 2em 1em 0 0 !important;
}

div:where(.swal2-container) div:where(.swal2-actions) button {
  padding: 0.75em 1.5em !important;
  border-radius: 20px !important;
  font-weight: 800 !important;
  transition: all 100ms linear !important;
  font-size: 1.1em !important;
}

div:where(.swal2-container)
  button:where(.swal2-styled).swal2-default-outline:focus {
  box-shadow: none !important;
}

.popup:has(.popup-content) {
  width: 75vw !important;
}

@media screen and (max-width: 900px) {
  .popup:has(.popup-content) {
    width: 95vw !important;
  }
}

.popup-content {
  text-align: left;
}

.popup-content .block {
  margin: 2em 0 0 0;
}

.popup-content .block .card {
  border: solid 1px var(--border-color);
  box-shadow: none;
  padding: 10px 30px;
}

.popup-content .block .card .skills {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-block: 0 10px;
}

.popup-content .block .card .skills .skill {
  background-color: var(--socraft-yellow);
  color: #000;
  padding: 5px 15px;
  border-radius: 10px;
}

.popup-content .block .card:not(:first-child) {
  margin: 1em 0 0 0;
}

.popup-content .flex .side .crafter-fullname {
  margin-block: 20px 10px;
  font-size: 2em;
  font-weight: 600;
  color: #000;
}

.popup-content .flex .side .crafter-job {
  color: var(--socraft-yellow);
  font-weight: 600;
}

.popup-content header.flex:not(.space-between) {
  gap: 3em;
}

.popup-content .flex .side .crafter-avatar {
  height: 200px;
}

.popup-content h2 {
  font-weight: 600;
}

.popup-content .popup-body {
  margin: 2em 0 0 0;
}

.popup-content .popup-body:has(.select-skill) {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.popup-content .popup-actions {
  margin: 2em 0 0 0;
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.popup-content .popup-actions button {
  font-size: 0.9em;
}

.select-skill {
  white-space: nowrap;
  display: flex;
  align-items: center;
  border: solid 1px var(--border-color);
  width: max-content;
  padding: 5px 10px;
  border-radius: 10px;
  gap: 10px;
}

.select-skill label {
  cursor: pointer;
}
