.input-group {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.5em 1em;
  background-color: var(--white);
  border: solid 1px var(--border-color);
  border-radius: 15px;
}

.input-group .no-content {
  opacity: 0.5;
}

.input-group:has(.skills),
.input-group:has(.no-content) {
  cursor: pointer;
}

.input-group .no-content::selection {
  background-color: transparent;
}

.input-group .skills {
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 5px;
  margin: 0 0 0 1px;
  border-radius: 0 10px 10px 0;
  transition: all 100ms linear;
}

.input-group:has(.skills):hover .skills {
  opacity: 0.8;
}

.input-group .skills::-webkit-scrollbar {
  display: none;
}

.input-group .skills .skill {
  white-space: nowrap;
  background-color: var(--socraft-yellow);
  color: var(--socraft-dark);
  padding: 3px 10px;
  border-radius: 10px;
}

.input-group:has(.content) {
  cursor: pointer;
}

.input-group:not(:first-child) {
  margin: 1em 0 0 0;
}

.input-group:has(input:read-only) {
  opacity: 0.5;
}

.input-group input,
.input-group select {
  border: none;
  width: 100%;
  font-size: 1em;
}

.input-group textarea {
  font-size: 1em;
  border: none;
  width: 100%;
  min-height: 21.5px;
  height: 21.5px;
  resize: vertical;
  max-height: 200px;
}

.input-group input:focus,
.input-group select:focus,
.input-group textarea:focus {
  outline: none;
}

.input-group:has(input:focus) {
  outline: solid 3px #fbbb1070;
  border-color: transparent;
}
