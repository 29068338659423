.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background-color: var(--page-background);
  display: flex;
  align-items: center;
  justify-content: center;
}

svg.container {
  --uib-size: 45px;
  --uib-color: var(--socraft-yellow);
  --uib-speed: 1.5s;
  --uib-bg-opacity: 0;
  height: var(--uib-size);
  width: var(--uib-size);
  transform-origin: center;
  animation: rotate var(--uib-speed) linear infinite;
  will-change: transform;
  overflow: visible;
}

.car {
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: stretch calc(var(--uib-speed) * 0.75) ease-in-out infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
}

.track {
  fill: none;
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: stroke 0.5s ease;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 75, 150;
    stroke-dashoffset: -25;
  }
  100% {
    stroke-dashoffset: -100;
  }
}
