.crafter-card {
  padding: 20px 25px;
  border-radius: 20px;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  flex: 1 0 auto;
}

.crafter-card .grid {
  margin: 0;
  width: 100%;
  column-gap: 2em;
}

.side {
  height: 100%;
}

.crafter-avatar {
  border-radius: 10px 20px;
  height: 160px;
}

.crafter-fullname {
  margin-block: 0;
  display: flex;
  align-items: center;
  gap: 7px;
}

.crafter-infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 0.5em;
}

.crafter-infos .crafter-info {
  position: relative;
}

.crafter-infos .crafter-info::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 5px;
  background-color: var(--socraft-yellow);
  height: 10px;
  width: 10px;
  border-radius: 2.5px;
}

.crafter-actions button {
  font-size: 0.9em;
  padding: 0.5em 1.35em;
  border-radius: 15px;
  float: right;
}
