.swal2-popup.swal2-toast.toast.swal2-show.success {
  background-color: var(--success) !important;
  display: flex !important;
  align-items: center !important;
}

.swal2-popup.swal2-toast.toast.swal2-show.error {
  background-color: var(--danger) !important;
}

.swal2-popup.swal2-toast.toast.swal2-show.warning {
  background-color: var(--warning) !important;
}

.swal2-toast .swal2-success-circular-line-left,
.swal2-toast .swal2-success-circular-line-right,
.swal2-toast .swal2-success-fix,
.swal2-toast .swal2-error-circular-line-left,
.swal2-toast .swal2-error-circular-line-right,
.swal2-toast .swal2-error-fix,
.swal2-toast .swal2-warning-circular-line-left,
.swal2-toast .swal2-warning-circular-line-right,
.swal2-toast .swal2-warning-fix {
  background-color: transparent !important;
}

.swal2-toast div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
  background-color: var(--white) !important;
}

.swal2-toast div:where(.swal2-icon).swal2-success [class^=swal2-success-line] {
  background-color: var(--white) !important;
}

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  left: 0.2875em !important;
}

.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  right: 0.088em !important;
}

.swal2-popup.swal2-toast.toast.swal2-show.warning {
  background-color: #ffa8bb !important;
  display: flex !important;
  align-items: center !important;
}
